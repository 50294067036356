@use '@angular/material' as mat;
@use '@pearsonvue/topaz-angular-ui' as topaz;
@use 'sass:map';
@use '@pearsonvue/topaz-angular-ui/assets/scss/theme/palettes' as p;

@include topaz.core-theme();
@include topaz.one-column-layout();
@include topaz.margin-utils();
@include topaz.padding-utils();

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.h3-title {
  font-size: 1rem;
  font-weight: bold;
}

.buttons {
  padding-block: 0.5rem;
  display: flex;
  gap: 1rem;
}

$color-config: mat.get-color-config(topaz.$topaz-primary-theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette: map.get($color-config, 'accent');
$warn-palette: map.get($color-config, 'warn');
$alert-palette: map.get(topaz.$topaz-primary-theme, 'alert');

@media screen and (max-width: 1024px) {
  .full-width-mobile {
    &.no-flex {
      display: initial;
      button {
        margin: 5px 0px;
        width: 100%;
        flex: 1 0 100%;
      }
    }
    button {
      flex: 0 0 100%;
    }
  }
}

button {
  &.tooltip-bttn {
    border: none;
    background: none;
    color: #666;
    font-size: 1.15rem;
    &.mdc-button {
      min-width: 24px !important;
      border-radius: 60px !important;
      height: 24px !important;
      padding: 0 !important;
      margin: 0px !important;
      fa-icon {
        margin-inline-start: 0px;
      }
      &.mleft-15 {
        margin-left: -15px !important;
      }
    }
    &:hover {
      box-shadow: 0 0 2px 4px #219ea3 !important;
      opacity: 1 !important;
      outline: none !important;
    }
  }
}
.full-width {
  button {
    width: 100%;
  }
}

.topaz-footer .topaz-footer-links ul li a {
  margin-inline: 0.8rem;
}

[dir='rtl'] {
  .mat-mdc-form-field.mat-mdc-form-field-has-icon-prefix {
    .mat-mdc-text-field-wrapper {
      padding-left: 1rem;
      --mat-mdc-form-field-label-offset-x: -40px;
    }

    mat-label {
      margin-right: 2rem;
    }
  }
}

.basic-checkboxes {
  button {
    background: none;
    border: none;
  }
}

:host {
  ::ng-deep {
    .toast-container {
      position: initial !important;
    }
  }
}

@media (max-width: 1024px) {
  .flex-m-order-1 {
    order: 1;
  }
  .flex-m-order-2 {
    order: 2;
  }
}

.red {
  color: var(--topaz-warn-500) !important;

  svg {
    color: var(--topaz-warn-500) !important;
  }
}
.green {
  color: var(--topaz-accent-midtone-grass-green-500) !important;

  svg {
    color: var(--topaz-accent-midtone-grass-green-500) !important;
  }
}
.black {
  color: var(--topaz-neutral-700) !important;
  svg {
    color: var(--topaz-neutral-700) !important;
  }
}

.bg-gray {
  background: mat.get-color-from-palette(p.$topaz-neutral, 300);
}

button.topaz-link-bold {
  padding: 3px !important;
  color: mat.get-color-from-palette(p.$topaz-blue, 600) !important;
  &:hover {
    border: none !important;
    background: none !important;
    outline: 0 !important;
  }
  &:focus {
    padding: 5px 5px;
    border: none !important;
  }
  font-weight: 700;
  text-decoration: underline;
}

.mat-form-field-invalid {
  .mat-mdc-form-field-icon-suffix {
    fa-icon svg {
      color: mat.get-color-from-palette(p.$topaz-red, 800) !important;
    }
  }
}

.topaz-banner {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.modal-close-bttn {
  position: absolute !important;
  right: 5px;
  top: 5px;
  padding: 4px !important;
}

@media screen and (min-width: map.get(topaz.$topaz-breakpoints-max, 'small')) {
  .min-height-400 {
    min-height: 400px;
  }
}

img.example-cert {
  max-width: 100%;
  background: url('../src/assets/images/reg_cert_example.svg');
}

@media screen and (max-width: 599px) {
  .topaz-dialog-header {
    justify-content: space-between;
  }

  //workaround solution to fix the Language modal issue.
  //will remove this code once topaz team fix the bug.
  @media screen and (max-height: 905px) {
    .locale-dialog-container .locale-tile-container {
      height: auto !important;
    }
  }
}

//workaround solution to fix the Language modal issue. 
//will remove this code once topaz team fix the bug.
@media screen and (max-height: 905px) {
  .locale-dialog-container .locale-tile-container {
      height: auto !important;
  }
}